
import {Component, Ref, Vue} from "vue-property-decorator";
import api from "@/api/index";
import PagedTableView from "@/components/PagedTableView/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";
import AgileAuditTag from "@/components/AgileAuditTag/index.vue";
import ImportExcel from "@/components/ImportExcel/index.vue";
import ExportButton from "@/components/ExportButton/index.vue";
import AuditFlowDetailButton from "@/components/AuditFlowDetailButton/index.vue";
import {ElForm} from "element-ui/types/form";
import {
  CapitalExpenseRecordDto,
  AuditFlowScope,
  CapitalExpenseRecordCreateOrUpdateDto
} from "@/api/appService";

@Component({
  name: "CapitalExpenseRecordList",
  components: {
    PagedTableView,
    AbSelect,
    AgileAuditTag,
    ImportExcel,
    ExportButton,
    AuditFlowDetailButton
  },
  filters: {
    formatDate(year: any, month: any) {
      if (month < 10) {
        month = "0" + month;
      }
      return year + "-" + month;
    },
    formatePayType(type: any, list: any[]) {
      let result = "";
      if (list && list.length > 0) {
        list.map(item => {
          if (item.value == type) {
            result = item.label;
            return;
          }
        });
      }
      return result;
    },
    formateProjectName(list: any[]) {
      let result = "";
      if (list && list.length > 0) {
        list.map((item, index) => {
          if (item.project) {
            if (!result) {
              result = item.project.displayName;
            } else {
              result = result + ',' + item.project.displayName;
            }
          }
        });
      }
      return result;
    },
  }
})
export default class CapitalExpenseRecordList extends Vue {
  @Ref() readonly dataForm!: ElForm;
  dialogFormVisible: boolean = false;
  dialogForm: any = {
    id: 0,
    payDate: undefined
  };
  queryForm: any = {
    projectName: "",
    payeeBankAccount: "",
    payeeName: "",
    contractNo: ""
  };
  //
  payTypeList = [
    {
      value: "Cash",
      label: "现金"
    },
    {
      value: "Transfer",
      label: "转账"
    }
  ];
  body: CapitalExpenseRecordCreateOrUpdateDto = {};

  fetchData(params: any) {
    return api.capitalExpenseRecord.getAll(params);
  }

  //获取审核状态
  getAuditStatus(index: number, row: any) {
    if ((row.audit != null && row.audit >= 0) && row.auditStatus === row.audit) {//已审核
      return true;
    } else if (row.audit === -1) {//被拒绝
      return false;
    } else if (row.audit == null) {//未开始
      return false;
    } else {//审核中
      return true;
    }
  }

  // 操作按钮
  handleMenu($row: any) {
    switch ($row.type) {
      case "detail":
        this.handleDetail($row.item);
        break;

      case "edit":
        this.handleEdit($row.index, $row.item);
        break;

      case "delete":
        this.handleDelete($row.index, $row.item);
        break;

      case "startAudit":
        this.handleStartAudit($row.index, $row.item);
        break;
      case "auditFlow":
        this.handleAuditFlow($row.index, $row.item);
        break;
      case "changeApply":
        this.handleChangeApply($row.index, $row.item);
        break;
      case "cancelChangeApply":
        this.handleCancelChangeApply($row.index, $row.item);
        break;
      case "changeRecord":
        this.handleChangeRecord($row.index, $row.item);
        break;
      case "dialog":
        this.handleDialog($row.index, $row.item);
        break;
    }
  }

  handleDialog(index: number, row: any) {
    this.$nextTick(() => {
      this.dataForm.clearValidate();
    });

    this.dialogForm.id = row.id;
    this.dialogForm.payDate = row.payDate;
    this.dialogFormVisible = true;
  }

  handleCreate() {
    this.$router.push({
      name: "expenseRecordCreate"
    });
  }

  handleEdit(index: number, row: any) {
    this.$router.push({
      name: "expenseRecordEdit",
      params: {
        id: row.id
      }
    });
  }

  handleDetail(row: any) {
    this.$router.push({
      name: "expenseRecordDetail",
      params: {
        id: row.id
      }
    });
  }

  async handleStartAudit(index: number, row: CapitalExpenseRecordDto) {
    await api.capitalExpenseRecord
      .startAudit({body: {id: row.id}})
      .then(() => {
        this.$message.success("已提交");
      });
  }

  handleChangeApply(index: number, row: any) {
    this.$router.push({
      name: "expenseRecordChange",
      params: {
        id: row.id
      }
    });
  }

  handleCancelChangeApply(index: number, row: any) {
    this.$confirm("你确定取消变更吗?", "提示").then(async () => {
      this.body.id = row.id;
      await api.capitalExpenseRecord
        .cancelChangeApply({body: this.body})
        .then(() => {
          this.$message({
            type: "success",
            message: "操作成功"
          });
        });
    });
  }

  handleAuditFlow(index: number, row: any) {
    this.$router.push({
      name: "auditFlowDetail",
      params: {
        hostType: AuditFlowScope.CapitalExpenseRecord,
        hostId: row.id,
        auditFlowId: row.auditFlowId
      }
    });
  }

  handleChangeRecord(index: number, row: any) {
    this.$router.push({
      name: "expenseRecordChangeRecord",
      params: {
        id: row.id
      }
    });
  }

  // 删除
  async handleDelete(index: number, row: CapitalExpenseRecordDto) {
    this.$confirm("你确定删除吗?", "提示").then(async () => {
      await api.capitalExpenseRecord.delete({id: row.id}).then(() => {
        this.$message({
          type: "success",
          message: "删除成功!"
        });
      });
    });
  }

  //
  async saveDialog() {
    console.log(this.dialogForm);
    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        if (this.dialogForm.id > 0) {
          await api.capitalExpenseRecord.savePayDate(this.dialogForm);
        } else {
        }
        this.dialogFormVisible = false;
        this.$message.success("更新成功");
      }
    });
  }

  dialogRule = {
    payDate: [
      {
        required: true,
        message: "请输入支付时间",
        trigger: "blur"
      }
    ]
  };
}
