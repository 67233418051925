
  import api from "@/api";
  import {Component, Vue, Ref} from "vue-property-decorator";
  import {DataDictionaryDto, PartnerInviteDto} from "@/api/appService";
  import PagedTableView from "@/components/PagedTableView/index.vue";
  import ImportExcel from "@/components/ImportExcel/index.vue";
  import moment from "moment";
  import EditPartnerInvite from "@/views/partnerInvite/edit.vue";
  import {stream} from "xlsx";

  @Component({
    name: "PartnerInviteList",
    components: {PagedTableView, EditPartnerInvite, ImportExcel},
    filters: {
      formatHasUsed(value: any) {
        switch (value) {
          case true:
            return "是"
          case false:
            return "否"
        }
      },
      orgType(value: any, list: any[]) {
        let result = '';
        if (list && list.length > 0) {
          list.map((item) => {
            if (String(item.id) == value) {
              result = item.displayName;
              return;
            }
          });
        }
        return result;
      }
    }
  })
  export default class PartnerInviteList extends Vue {
    queryForm: any = {
      organizationName: '',
      emailAddress: '',
      mobile: '',
      content: '',
    }

    editPartnerInviteId: number = 0;
    orgTypeList: DataDictionaryDto[] | undefined= [];

    created() {
      this.fetchDataDictionary()
    }

    fetchDataDictionary() {
      api.dataDictionary.getDataDictionaryListByKey({key: 'OrganizationUnitType',maxResultCount:1000})
        .then((res)=>{
          this.orgTypeList = res.items
        })
    }

    fetchData(params: any) {
      return api.partnerInvite.getAll(params);
    }

    // 新建
    handleCreate() {
      this.editPartnerInviteId = 0;
      (this.$refs.editForm as any).show = true;
    }

    //


    // 跳转编辑页
    handleEdit(index: number, row: PartnerInviteDto) {
      this.editPartnerInviteId = row.id!;
      (this.$refs.editForm as any).show = true;
      (this.$refs.editForm as any).form = row;
    }

    // 删除
    handleDelete(index: number, row: PartnerInviteDto) {
      this.$confirm('确定删除吗?', '提示').then(() => {
        api.partnerInvite.delete({id: row.id}).then(res => {
          this.$message.success('删除成功');
        });
      })
    }
  }
