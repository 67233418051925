
import {Component, Vue} from "vue-property-decorator";
import api from "@/api/index";
import PagedTableView from "@/components/PagedTableView/index.vue";
import {ProjectDetailReprotDto,} from "@/api/appService";

@Component({
  name: "projectReportRecordList",
  components: {
    PagedTableView,

  }
})
export default class ProjectReportRecordList extends Vue {
  queryForm: any = {
    itemName: "",
    remark: "",
    summary: "",
    startDate: "",
    endDate: "",
    minAmount: "",
    maxAmount: "",
    projectId: ""
  };
  body: ProjectDetailReprotDto = {};

  created() {
    this.queryForm.projectId = Number(this.$route.params.id);
  }

  fetchData(params: any) {
    return api.capitalExpenseRecord.getProjectRepprtDetailList(params);
  }

  back() {
    this.$router.go(-1);
  }

}
