
import { Component, Ref, Vue } from "vue-property-decorator";
import {
  AuditFlowScope,
  CapitalExpenseRecordDto,
  CapitalExpenseRecordDetailDto,
  ProjectFullInfoDto,
  ProjectDto
} from "@/api/appService";
import ExportWord from "@/components/DownloadWord/index.vue";
import api from "@/api";
import AuditButtons from "@/components/AuditButtons/index.vue";
import { AuditBodyDto } from "@/utils/customDto";

@Component({
  name: "CapitalExpenseRecordDetail",
  components: {
    ExportWord,
    AuditButtons
  },
  filters: {
    formatePayType(type: any, list: any[]) {
      let result = "";
      if (list && list.length > 0) {
        list.map(item => {
          if (item.value == type) {
            result = item.label;
            return;
          }
        });
      }
      return result;
    }
  }
})
export default class CapitalExpenseRecordDetail extends Vue {
  @Ref() auditButtons!: AuditButtons;
  orgId?: number;
  project: ProjectDto = {};
  details: CapitalExpenseRecordDetailDto[] = [];
  detail: CapitalExpenseRecordDto = {
    details: this.details
  };

  auditParams: AuditBodyDto = {
    hostId: undefined,
    hostType: AuditFlowScope.CapitalExpenseRecord,
    auditFlowId: undefined,
    auditStatus: undefined,
    audit: undefined
  };

  partnerOrgList: ProjectFullInfoDto[] = [];
  selector: string = "table-detail";
  filename: string = "支出记录单";
  styles: string = "";
  //
  payTypeList = [
    {
      value: "Cash",
      label: "现金"
    },
    {
      value: "Transfer",
      label: "转账"
    }
  ];

  created() {
    this.fetchProjectAll();

    if (this.$route.params.id) {
      this.orgId = Number(this.$route.params.id);
      this.fetchDetail();
    }
  }

  fetchAuditData() {
    return api.capitalExpenseRecord.audit;
  }

  // 获取详情
  async fetchDetail() {
    await api.capitalExpenseRecord.get({ id: this.orgId }).then(res => {
      this.detail = { ...res };
      this.auditParams!.hostId = res.id + "";
      this.auditParams!.auditFlowId = res.auditFlowId;
      this.auditParams!.auditStatus = res.auditStatus;
      this.auditParams!.audit = res.audit;
      if (res.auditFlowId) {
        (this.auditButtons as any).fetchCanAudit();
      }
    });
  }

  changeAudit() {
    this.fetchDetail();
  }

  formatPartnerOrg(projectId: number, orgList: ProjectFullInfoDto[]) {
    let projectName = "";
    orgList.filter(function(item) {
      if (projectId == item.id) {
        projectName = item.displayName ?? "";
      } else if (projectId == null) {
        projectName = "待分摊";
      }
    });
    return projectName;
  }

  // 获取机构
  async fetchProjectAll() {
    await api.project.getAll({ maxResultCount: 2000,isIgnorePublicShow:true }).then((res: any) => {
      if (res.items) {
        this.partnerOrgList = res.items;
      }
    });
  }

  // 返回
  private cancel() {
    this.$router.back();
  }
}
